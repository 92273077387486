let Constants = null;

const getConstants = () => {
  // Se asigna al sessionStorage desde el Header.jsx
  Constants = sessionStorage.getItem("constants");
  if (Constants) {
    return JSON.parse(Constants);
  }
  return null;
};

export const getImgDefault = () => {
  Constants = getConstants();
  if (Constants && Constants.imgDefault) {
    return Constants.imgDefault;
  }
  return "/resources/imagenUnavailable.jpg";
};

export const getIdStreaming = () => {
  Constants = getConstants();
  if (Constants && Constants.idStreaming) {
    return Constants.idStreaming;
  }
  return "";
};

export const getExecution = () => {
  Constants = getConstants();
  if (Constants && Constants.execution) {
    return Constants.execution;
  }
  return "";
};

export const getIdGoldenMinutes = () => {
  Constants = getConstants();
  if (Constants && Constants.idGoldenMinutes) {
    return Constants.idGoldenMinutes;
  }
  return "";
};

export const getIdPresential = () => {
  Constants = getConstants();
  if (Constants && Constants.idPresential) {
    return Constants.idPresential;
  }
  return "";
};

export const getScheduled = () => {
  Constants = getConstants();
  if (Constants && Constants.scheduled) {
    return Constants.scheduled;
  }
  return "";
};

export const getDirectSale = () => {
  Constants = getConstants();
  if (Constants && Constants.directSale) {
    return Constants.directSale;
  }
  return "";
};

export const getPartialClosure = () => {
  Constants = getConstants();
  if (Constants && Constants.partialClosure) {
    return Constants.partialClosure;
  }
  return "";
};

export const getPartialTotal = () => {
  Constants = getConstants();
  if (Constants && Constants.partialTotal) {
    return Constants.partialTotal;
  }
  return "";
};

/*export const getAddresShipping = () => {
  Constants = getConstants();
  if (Constants && Constants.addresShipping) {
    return Constants.addresShipping;
  }
  return '';
};

export const getAddresBilling = () => {
  Constants = getConstants();
  if (Constants && Constants.addresBilling) {
    return Constants.addresBilling;
  }
  return '';
};*/

export const getCarouselTime = () => {
  Constants = getConstants();
  if (Constants && Constants.carouselTime) {
    return Constants.carouselTime;
  }
  return "";
};
export const getStreamingToStart = () => {
  Constants = getConstants();
  if (Constants && Constants.streamingToStart) {
    return Constants.streamingToStart;
  }
  return "";
};
export const getStreamingInPause = () => {
  Constants = getConstants();
  if (Constants && Constants.streamingInPause) {
    return Constants.streamingInPause;
  }
  return "";
};
export const getStreamingInProcess = () => {
  Constants = getConstants();
  if (Constants && Constants.streamingInProcess) {
    return Constants.streamingInProcess;
  }
  return "";
};
export const getStreamingEnded = () => {
  Constants = getConstants();
  if (Constants && Constants.streamingEnded) {
    return Constants.streamingEnded;
  }
  return "";
};

export const getLimitShippingInvoice = () => {
  Constants = getConstants();
  if (Constants && Constants.limitShippingInvoice) {
    return Constants.limitShippingInvoice;
  }
  return "";
};

export const getLimitShipping = () => {
  Constants = getConstants();
  if (Constants && Constants.limitShipping) {
    return Constants.limitShipping;
  }
  return "";
};

export const getIdSystem = () => {
  Constants = getConstants();
  if (Constants && Constants.idSystem) {
    return Constants.idSystem;
  }
  return "";
};

export const getReferenceObject = () => {
  Constants = getConstants();
  if (Constants && Constants.referenceObject) {
    return Constants.referenceObject;
  }
  return "";
};

export const getZipCodeOrigenStreaming = () => {
  Constants = getConstants();
  if (Constants && Constants.zipCodeOrigenStreaming) {
    return Constants.zipCodeOrigenStreaming;
  }
  return "";
};

export const getZipCodeOrigenMO = () => {
  Constants = getConstants();
  if (Constants && Constants.zipCodeOrigenMO) {
    return Constants.zipCodeOrigenMO;
  }
  return "";
};

export const getZipCodeOrigenPresential = () => {
  Constants = getConstants();
  if (Constants && Constants.zipCodeOrigenPresential) {
    return Constants.zipCodeOrigenPresential;
  }
  return "";
};

export const getAvailableLot = () => {
  Constants = getConstants();
  if (Constants && Constants.available) {
    return Constants.available;
  }
  return "";
};

export const getRetiredLot = () => {
  Constants = getConstants();
  if (Constants && Constants.retired) {
    return Constants.retired;
  }
  return "";
};
export const getAwardLot = () => {
  Constants = getConstants();
  if (Constants && Constants.award) {
    return Constants.award;
  }
  return "";
};

export const getPenaltyDays = () => {
  Constants = getConstants();
  if (Constants && Constants.penaltyDays) {
    return Constants.penaltyDays;
  }
  return "";
};

export const getPenaltyDaysDefault = () => {
  Constants = getConstants();
  if (Constants && Constants.penaltyDaysDefault) {
    return Constants.penaltyDaysDefault;
  }
  return "";
};

export const getDeliveryMail = () => {
  Constants = getConstants();
  if (Constants && Constants.deliveryMail) {
    return Constants.deliveryMail;
  }
  return "";
};

export const getDeliveryOffice = () => {
  Constants = getConstants();
  if (Constants && Constants.deliveryOffice) {
    return Constants.deliveryOffice;
  }
  return "";
};

export const getGuidEmpty = () => {
  Constants = getConstants();
  if (Constants && Constants.guidEmpty) {
    return Constants.guidEmpty;
  }
  return "";
};

export const getLotPaymentLimit = () => {
  Constants = getConstants();
  if (Constants && Constants.lotPaymentLimit) {
    return Constants.lotPaymentLimit;
  }
  return "";
};

export const getLotPaymentLimitDefault = () => {
  Constants = getConstants();
  if (Constants && Constants.lotPaymentLimitDefault) {
    return Constants.lotPaymentLimitDefault;
  }
  return "";
};

export const getStreamPlayBackUrlQA = () => {
  Constants = getConstants();
  if (Constants && Constants.streamPlayBackUrlQA) {
    return Constants.streamPlayBackUrlQA;
  }
  return "";
};
export const getStreamPlayBackUrlUAT = () => {
  Constants = getConstants();
  if (Constants && Constants.streamPlayBackUrlUAT) {
    return Constants.streamPlayBackUrlUAT;
  }
  return "";
};
export const getUserRejectedStatus = () => {
  Constants = getConstants();
  if (Constants && Constants.userRejected) {
    return Constants.userRejected;
  }
  return "";
};
export const getOrderPendingStatus = () => {
  Constants = getConstants();
  if (Constants && Constants.orderPending) {
    return Constants.orderPending;
  }
  return "";
};

export const getOrderPendingPaymentStatus = () => {
  Constants = getConstants();
  if (Constants && Constants.orderPendingPayment) {
    return Constants.orderPendingPayment;
  }
  return "";
};

export const getOrderCompletedStatus = () => {
  Constants = getConstants();
  if (Constants && Constants.orderCompletedStatus) {
    return Constants.orderCompletedStatus;
  }
  return "";
};

export const getnoteCancelled = () => {
  Constants = getConstants();
  if (Constants && Constants.noteCancelled) {
    return Constants.noteCancelled;
  }
  return "";
};
export const getBillPocketID = () => {
  Constants = getConstants();
  if (Constants && Constants.billPocket) {
    return Constants.billPocket;
  }
  return "";
};
export const getactnetID = () => {
  Constants = getConstants();
  if (Constants && Constants.actnet) {
    return Constants.actnet;
  }
  return "";
};

export const getMercadoPagoId = () => {
  Constants = getConstants();
  if (Constants && Constants["mercadoPago"]) {
    return Constants["mercadoPago"];
  }
  return "";
};

export const getActivateMessagingID = () => {
  Constants = getConstants();
  if (Constants && Constants.activateMessaging) {
    return Constants.activateMessaging;
  }
  return "";
};
export const getDeliveryLimitID = () => {
  Constants = getConstants();
  if (Constants && Constants.deliveryLimit) {
    return Constants.deliveryLimit;
  }
  return "";
};
export const getWarrantyID = () => {
  Constants = getConstants();
  if (Constants && Constants.warranty) {
    return Constants.warranty;
  }
  return "";
};
export const getPaymentBillPocketID = () => {
  Constants = getConstants();
  if (Constants && Constants.paymentBillPocket) {
    return Constants.paymentBillPocket;
  }
  return "";
};
export const getPriceFilterID = () => {
  Constants = getConstants();
  if (Constants && Constants.priceFilterID) {
    return Constants.priceFilterID;
  }
  return "";
};
export const getBillingUrl = () => {
  Constants = getConstants();
  if (Constants && Constants.billing) {
    return Constants.billing;
  }
  return "";
};
export const getDiamondUrl = () => {
  Constants = getConstants();
  if (Constants && Constants.diamond) {
    return Constants.diamond;
  }
  return "";
};

export const getDisableCheckoutMessageID = () => {
  Constants = getConstants();
  if (Constants && Constants.idDisableCheckoutMessage) {
    return Constants.idDisableCheckoutMessage;
  }
  return "";
};

export const getMessageInsuranceID = () => {
  Constants = getConstants();
  if (Constants && Constants.messageInsuranceID) {
    return Constants.messageInsuranceID;
  }
  return "";
};

export const getMercadoPagoPublicKey = () => {
  Constants = getConstants();
  if (Constants && Constants["idMercadoPagoPublicKey"]) {
    return Constants["idMercadoPagoPublicKey"];
  }
  return "";
};
