import React, { useContext, useState } from 'react';

import { POST } from '../../helpers/APIServices';
import { ServiceLogin } from '../../helpers/getURL';
import {
  CloseModal,
  OpenMessage,
  restoreInputs,
  verifyFieldsRequerid,
  validEmail,
  setSession,
  onlyMail,
  changePasswordText,
} from '../Utilities/General';

import '../../css/Login/Login.css';
import { useHistory } from 'react-router-dom';
import { DataContext } from '../../context/DataContext';
import { CloseModalIcon } from '../Home/CloseModalIcon';

const Login = () => {
  const [inputMail, setinputMail] = useState('');
  const [inputPassword, setinputPassword] = useState('');
  const [disabledBtn, setdisabledBtn] = useState(false);
  const URLLogin = ServiceLogin();
  let history = useHistory();
  const { pathname = '/', search = '' } = history.location;
  const inputs = ['input-mail', 'input-password'];
  const { setValidate } = useContext(DataContext);

  const clearState = () => {
    setinputMail('');
    setinputPassword('');
    restoreInputs(inputs);
    CloseModal('modalLogin');
  };

  const Logueo = () => {
    const validFields = verifyFieldsRequerid(inputs);

    if (!validFields) {
      OpenMessage('error', 'Campos obligatorios');
      return false;
    }
    if (!validEmail(inputMail)) {
      document.getElementById('input-mail').classList.add('is-invalid');
      OpenMessage('error', 'El formato del correo electrónico no es válido');
      return false;
    }
    setdisabledBtn(true);
    const params = {
      email: inputMail,
      password: inputPassword,
    };
    POST(URLLogin, params).then((data) => {
      setdisabledBtn(false);
      if (data.token) {
        setValidate({
          valid: false,
          registeredAuctions: data.auctions,
          idAuction: '',
          nameAuction: '',
          dateAuction: ' ',
          palletAuction: '',
          idPallet: '',
          onPaymentValidate: false,
        });
        clearState();
        setSession(true, data);
        document.getElementById('refreshToken').value = '0';
        history.push(`${pathname}${search}`);
      } else {
        OpenMessage('error', data);
      }
    });
  };

  const enterKey = (keyCode) => {
    if (keyCode === 13) {
      Logueo();
    }
  };

  return (
    <div className="modal" id="modalLogin" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header modal-header-login mt-2 justify-content-end">
            <CloseModalIcon onHandleClick={clearState} />
          </div>
          <div className="modal-body">
            <div className="text-center mt-2">
              <img src="./resources/logo.svg" alt="logo MLS" className="img-fluid" width="65%" />
            </div>
            <div className="text-center mb-4 mt-4">
              <h4 className="modal-title fw-bold text-uppercase">Iniciar Sesión</h4>
            </div>
            <div className="mb-3 form-outline container-input-login">
              <input
                type="text"
                className="form-control"
                id="input-mail"
                value={inputMail}
                onChange={(e) => setinputMail(e.target.value)}
                maxLength="80"
                onInput={(e) => onlyMail(e)}
                onKeyUp={(e) => enterKey(e.keyCode)}
                autoComplete="off"
              />
              <label htmlFor="input-mail" className="form-label">
                Correo electrónico:
              </label>
              <div className="form-notch">
                <div className="form-notch-leading"></div>
                <div className="form-notch-middle"></div>
                <div className="form-notch-trailing"></div>
              </div>
            </div>
            <div className="mb-3 form-outline input-group input-password container-input-login">
              <input
                type="password"
                className="form-control"
                id="input-password"
                value={inputPassword}
                onChange={(e) => setinputPassword(e.target.value)}
                maxLength="50"
                aria-describedby="icon-eyes-login"
                onKeyUp={(e) => enterKey(e.keyCode)}
              />
              <i className="fas fa-eye-slash" id="icon-eyes-login" onClick={(e) => changePasswordText('input-password', e)}></i>

              <label htmlFor="input-password" className="form-label">
                Contraseña:
              </label>
              <div className="form-notch">
                <div className="form-notch-leading"></div>
                <div className="form-notch-middle"></div>
                <div className="form-notch-trailing"></div>
              </div>
            </div>
          </div>
          <div className="modal-footer modal-header-login">
            <div className="container-fluid">
              <div className="row">
                <div className="col text-center mb-3">
                  <button type="button" className="btn btn-outline-secondary btn-lg" onClick={Logueo} disabled={disabledBtn}>
                    Iniciar sesión
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col text-center mb-3">
                  <a href="/recuperarpwd">¿Olvidó su contraseña?</a>
                </div>
              </div>
              <div className="row">
                <div className="col text-center mb-4">
                  <label>
                    ¿No eres miembro?&nbsp;
                    <a href="!#" data-bs-toggle="modal" data-bs-target="#modalCheckIn" onClick={clearState}>
                      Registrate
                    </a>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
