import { Fragment, useContext, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

//Componentes
import { Home } from './components/Home/Home';
import Header from './components/Home/Header';
import FooterMain from './components/Home/FooterMain';
//MENU
import Catalog from './components/Menu/Catalog';
import { AboutUs } from './components/Menu/AboutUs';
import { AuctionsMenu } from './components/Menu/AuctionsMenu';
import { Services } from './components/Menu/Services';
import { Contact } from './components/Menu/Contact';
//Utilidades
import ToTop from './components/Utilities/ToTop';
import Loading from './components/Utilities/Loading';
import { Message } from './components/Message/Message';
import { StreamingRegisterMessage } from './components/Register/StreamingRegisterMessage';
import { OfferSuccess } from './components/Menu/OfferSuccess';
//Recuperar contraseña
import { RecoveryPassword } from './components/Login/RecoveryPassword';
import { ResetPassword } from './components/Login/ResetPassword';
import { TokenNotValid } from './components/Login/TokenNotValid';
import { VerifyUser } from './components/CheckIn/VerifyUser';

//Perfil
import { ProfileGrid } from './components/Profile/ProfileGrid';
import { PaymentProcessGrid } from './components/Profile/paymentProcess/PaymentProcessGrid';
import { ReceivePay } from './components/Profile/paymentProcess/ReceivePay';
import { OrderDetailGrid } from './components/Profile/orderDetail/OrderDetailGrid';

//Straming
import { Streaming } from './components/streaming/Streaming';

//helpers
import { getConfig } from './helpers/getConfig';

//CSS de la aplicacion
import './css/App.css' //reset de estilos y variables de colores
import './css/Animations.css' //animaciones
import { RegisterToOffer } from './components/Register/RegisterToOffer'
import { DataProvider } from './context/DataContext'
import { ClosingModal } from './components/streaming/MiniPlayer/ClosingModal'
import { WaitNextAuctions } from './components/streaming/WaitNextAuctions'
import { WaitNextAuctionsDeclineClient } from './components/streaming/WaitNextAuctionsDeclineClient'
import { ReceivePayToken } from './components/Register/ReceivePayToken'
import { ReceiveActnetPay } from './components/Profile/paymentProcess/ReceiveActnetPay'
import { TokenStatusContext } from './context/TokenStatusContext'
import { getMercadoPagoPublicKey } from './helpers/getConstants'
import { ServiceGenericParameters } from './helpers/getURL'
import { GET } from './helpers/APIServices'
import { initMercadoPago } from '@mercadopago/sdk-react'
import ChallengePayToken from './components/Register/MePago/ChallengePayToken'

function App () {
  const { setMercadoPagoInitialized } = useContext(TokenStatusContext)

  useEffect(() => {
    const urlServices = sessionStorage.getItem('URLServices')
    if (!urlServices || urlServices.length <= 2) {
      getConfig('urlServices.json').then((response) => {
        const url = JSON.stringify(response)
        sessionStorage.setItem('URLServices', url)
        window.location.reload()
      })
    }
    else {
      const mercadoPagoPublicKey = localStorage.getItem('MercadoPagoPublicKey')
      if (!mercadoPagoPublicKey) {
        const idMercadoPagoPublicKey = getMercadoPagoPublicKey();
        const endpointMercadoPagoPublicKey = ServiceGenericParameters(idMercadoPagoPublicKey);
        GET(endpointMercadoPagoPublicKey).then((data) => {
          if (data && data.value) {
            localStorage.setItem('MercadoPagoPublicKey', data.value);
            initMercadoPago(data.value, {locale: "es-MX"});
            setMercadoPagoInitialized(true);
          }
        });
      }
      else {
        initMercadoPago(mercadoPagoPublicKey, {locale: "es-MX"});
        setMercadoPagoInitialized(true);
      }
    }
  }, []);

  return (
    <DataProvider>
      <Router>
        <Fragment>
          <Header />
          <ToTop />
          <Loading />
          <Message />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/quienes-somos" exact component={AboutUs} />
            <Route path="/subastas" exact component={AuctionsMenu} />
            <Route path="/catalogo" exact component={Catalog} />
            <Route path="/servicios" exact component={Services} />
            <Route path="/contacto" exact component={Contact} />
            <Route path="/recuperarpwd" exact component={RecoveryPassword} />
            <Route path="/resetpwd" exact component={ResetPassword} />
            <Route path="/expire" exact component={TokenNotValid} />
            <Route path="/perfil" exact component={ProfileGrid} />
            <Route path="/verificar-usuario" exact component={VerifyUser} />
            <Route path="/streaming" exact component={Streaming} />
            <Route path="/carrito-compras" exact component={PaymentProcessGrid} />
            <Route path="/validarPago" exact component={ReceivePay} />
            <Route path="/detalle-orden" exact component={OrderDetailGrid} />
            <Route path="/validarTokenPago" exact component={ReceivePayToken} />
            <Route path="/confirmarTokenPago" exact component={ChallengePayToken} />
            <Route path="/validatePaymentGateway" exact component={ReceiveActnetPay} />
            {/* DEFAULT */}
            <Route exact component={Home} />
          </Switch>
          <FooterMain />
          <RegisterToOffer />
          <StreamingRegisterMessage />
          <OfferSuccess />
          <ClosingModal />
          <WaitNextAuctions />
          <WaitNextAuctionsDeclineClient />
        </Fragment>
      </Router>
    </DataProvider>
  );
}

export default App;
